@import url(https://fonts.googleapis.com/css2?family=Contrail+One&display=swap);
html{margin:0;max-width:100vw}body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
a:hover{text-decoration:none !important}.ant-card{border-radius:7px;box-shadow:11px 13px 5px -6px rgba(0,0,0,.47)}.home-card-content .ant-card-body{padding-top:0}.map{box-shadow:11px 13px 5px -6px rgba(0,0,0,.47)}.App-logo{height:40vmin;pointer-events:none}.header{display:flex;align-items:center;justify-content:center;color:#000}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.centered-loader{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.ant-menu-item a{vertical-align:middle}.ant-menu-item-icon{vertical-align:baseline}div.loader-bg{background:#151530 !important}.jumbotron h2{margin:0;font-weight:bold;color:#fff;font-family:"Contrail One",cursive;font-size:2.5rem}.jumbotron{background:inherit !important;margin-bottom:0 !important}img.fixed-img{width:250px;height:200px}.anticon{vertical-align:middle}.ant-page-header-heading-title{color:#fff;font-weight:bold}.ant-page-header-heading-sub-title{color:#fff}.sport-edit-label label{color:#fff;font-weight:bold;width:120px}.individual-list .ant-list-item-meta-title{margin-bottom:0px !important}.individual-list .ant-list-item{padding:5px 0px}.ant-result-title,.ant-result-subtitle{color:#fff}body,.ant-layout-content{background-color:#8bc6ec;background-image:linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)}.ul-list>li{margin-bottom:10px}.ant-list-vertical .ant-list-item-meta-title{margin-bottom:0}
.hero{background:linear-gradient(to bottom, rgba(245, 246, 252, 0.25), rgba(21, 21, 48, 0.85)),url(/static/media/woman-running.37621892.jpg);background-size:cover;background-position:center;width:100vw;height:100vh}.hero-image{background:url(/static/media/winner-man.89a5617d.jpg);width:100vw;max-width:100vw;background-size:cover;background-position:center;padding-bottom:35%}.hero-title{text-align:center;color:#fff;font-size:calc(0.75em + 4vmin);font-weight:700;padding:0}.hero-phrase{font-size:20px;line-height:25px;margin:0;width:50%}.hero-overview{display:flex;justify-content:center;flex-wrap:wrap;margin-top:30px}.hero-progress{margin:20px;padding:15px;border:1px solid gray;border-radius:5px;width:50vw;max-width:700px}.hero-progress-text{width:90%;margin:0 10px;font-weight:800}.hero-progress-bar{width:90%;margin:0 10px}.hero-recent{display:flex;flex-direction:column}.recent-container{min-width:30vw}.testimonial-list-item .ant-list-item-meta-title{color:#fff;font-weight:bold}.testimonial-list-item .ant-list-item-meta-description{color:#fff}.ant-list-item-meta.testimonial-list-item{background:rgba(0,0,0,.262);padding:10px;border-radius:7px}
.register{display:flex;flex-direction:column;justify-content:center;align-content:center;max-width:50%;margin:auto;padding-top:2%}.intro{margin-bottom:2rem}.activities-wrapper{width:100%;padding-top:2rem;border-top:1px solid #eee}.activities-wrapper .ant-input-number{width:100%}.activities-wrapper .activity-row{position:relative}.activities-wrapper .activity-row .deleteBtn{position:absolute;right:-1.5rem;top:.6rem}
.strava-btn {
  background-color: #fc5200 !important;
  color: white !important;
  font-weight: 700;
  border: 0px solid black !important;
}

.strava-btn:hover {
  background-color: #cc4200 !important;
  color: white !important;
  border: 0px solid black !important;
}

.profile-container{display:flex;width:90%;margin:auto;justify-content:space-evenly;background-color:#fff}
