@import "~fontsource-inter/index.css";

.hero {
  background: linear-gradient(to bottom, rgba(245, 246, 252, 0.25), rgba(21, 21, 48, 0.85)),url("../../../assets/woman-running.jpg");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
}


.hero-image {
  background: url("../../../assets/winner-man.jpg");
  width: 100vw;
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  padding-bottom: 35%;
}

.hero-title {
  text-align: center;
  color: white;
  font-size: calc(0.75em + 4vmin);
  font-weight: 700;
  padding: 0;
}

.hero-phrase {
  font-size: 20px;
  line-height: 25px;
  margin: 0;
  width: 50%;
}

.hero-overview {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.hero-progress {
  margin: 20px;
  padding: 15px;
  border: 1px solid grey;
  border-radius: 5px;
  width: 50vw;
  max-width: 700px;
}

.hero-progress-text {
  width: 90%;
  margin: 0 10px;
  font-weight: 800;
}

.hero-progress-bar {
  width: 90%;
  margin: 0 10px;
}

.hero-recent {
  display: flex;
  flex-direction: column;
}

.recent-container {
  min-width: 30vw;
}

.testimonial-list-item .ant-list-item-meta-title {
  color: white;
  font-weight: bold;
}
.testimonial-list-item .ant-list-item-meta-description {
  color: rgba(255, 255, 255, 1);
}

.ant-list-item-meta.testimonial-list-item {
  background: rgba(0, 0, 0, 0.262);
  padding: 10px;
  border-radius: 7px;
}