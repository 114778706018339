@import "~antd/dist/antd.css";

body {
  
}

a:hover {
  text-decoration: none !important;
}

.ant-card {
  border-radius: 7px;
  -webkit-box-shadow: 11px 13px 5px -6px rgba(0,0,0,0.47);
  -moz-box-shadow: 11px 13px 5px -6px rgba(0,0,0,0.47);
  box-shadow: 11px 13px 5px -6px rgba(0,0,0,0.47);
}
.home-card-content .ant-card-body {
  padding-top: 0;
}

.map {
  -webkit-box-shadow: 11px 13px 5px -6px rgba(0,0,0,0.47);
  -moz-box-shadow: 11px 13px 5px -6px rgba(0,0,0,0.47);
  box-shadow: 11px 13px 5px -6px rgba(0,0,0,0.47);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-loader {
  position : absolute;
  top      : 50%;
  left     : 50%;
  transform: translate(-50%, -50%);
}

.ant-menu-item a {
  vertical-align: middle;
}

.ant-menu-item-icon {
  vertical-align: baseline;
}

div.loader-bg {
  background: #151530 !important;
}

/* .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  color: white !important;
  background: #151530;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, 
.ant-menu-item:hover {
  background: rgb(34, 34, 68) !important ;
} */
.jumbotron h2 {
  margin: 0;
  font-weight: bold;
  color: white;
  font-family: 'Contrail One', cursive;
  font-size: 2.5rem;
}

.jumbotron {
  background: inherit !important;
  margin-bottom: 0 !important;
}

img.fixed-img {
  width: 250px;
  height: 200px;
}


.anticon {
  vertical-align: middle;
}

.ant-page-header-heading-title {
  color: white;
  font-weight: bold;
}
.ant-page-header-heading-sub-title {
  color: white;
}

.sport-edit-label label {
  color: white;
  font-weight: bold;
  width: 120px;
}

/* .ant-pagination-slash, .ant-pagination-simple-pager {
  color: white;
  font-weight: bold;
}
.ant-pagination-simple-pager input {
  color: black
} */

.individual-list .ant-list-item-meta-title {
  margin-bottom: 0px !important;
}

.individual-list .ant-list-item {
  padding: 5px 0px;
}



/* .collapse-list, div.ant-collapse-header {
  background: rgb(21, 21, 48);
  font-weight: bold;
  color: white !important;
}

div.ant-collapse-content-box {
  font-weight: normal;
  background: #151530;
  color: white;
} */

.ant-result-title, .ant-result-subtitle {
  color: white;
}

body, .ant-layout-content {
  background-color: #8BC6EC;
background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);

}

.ul-list > li {
  margin-bottom: 10px;
}

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0;
}
