.strava-btn {
  background-color: #fc5200 !important;
  color: white !important;
  font-weight: 700;
  border: 0px solid black !important;
}

.strava-btn:hover {
  background-color: #cc4200 !important;
  color: white !important;
  border: 0px solid black !important;
}
