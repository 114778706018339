.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 50%;
  margin: auto;
  padding-top: 2%;
}

.intro {
  margin-bottom: 2rem;
}

.activities-wrapper {
  width: 100%;
  padding-top: 2rem;
  border-top: 1px solid #EEE;

  .ant-input-number {
    width: 100%;
  }

  .activity-row {
    position: relative;

    .deleteBtn {
      position: absolute;
      right: -1.5rem;
      top: .6rem;
    }
  }
}